<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="450px" height="700px" scrollable persistent>
      <v-card>
        <!-- <v-card-title>
          <span class="headline">Use Google's location service?</span>
        </v-card-title> -->
        <!-- <v-divider></v-divider> -->
        <v-card-text style="height; 700px" class="pa-1">
           <iframe :src="url" width="100%" height="700" embedded="true" referrerpolicy="no-referrer"></iframe>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <!-- <v-card-actions class="pt-1">
          <v-spacer></v-spacer>
          <v-btn color="error" small @click="$emit('close')" disabled>ปิด</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
    name: 'dialog_loginsso',
    props: ["display"],
    data: function() {
        return {
            dialog: false,
            url: ''
        }
    },
    watch:{
        display: function(val) {
            this.dialog = val
            if(this.dialog == true){
                this.url = process.env.VUE_APP_ONEIDSSO
            }else{
                this.url = ''
            }
        }
    }
    
}
</script>